
  export default {
    components: {
      CartItemInfo: () => import('./CartItemInfo'),
      CartItemsViewPartPrice: () => import('./CartItemsViewPartPrice'),
    },
    data() {
      return {
        cartItemToDelete: null,
      };
    },
    props: {
      cartType: {
        type: String,
        default: 'Cart',
      },
      orders: {
        type: Array,
        default: () => [],
      },
      notAbleToBuyMessage: {
        type: Array,
        default: () => [],
      },
      configurable: {
        type: Boolean,
        default: true,
      },
      canEdit: {
        type: Boolean,
        default: true,
      },
      hideTotals: Boolean,
    },
    methods: {
      outOfStock(order) {
        // console.log('this.notAbleToBuyMessage', this.notAbleToBuyMessage);
        return this.notAbleToBuyMessage.find((i) => i.poId === order.poId);
      },
      orderEditLink(order) {
        const editCartRoute =
          this.cartType === 'Quote' ? 'quickquote' : this.cartType === 'Proof' ? 'artproof' : 'configureproduct';
        const query = {
          path: `${
            order.fromProductView
              ? 'products'
              : order.type === 'New Sample' || order.type === 'Sample'
              ? 'sample'
              : order.designerConfig
              ? 'designer'
              : editCartRoute
          }/${order.productSlug}`,
          query: {
            orderId: order.orderId,
            poId: order.poId,
            decoration: order.decorationName,
            location: order.locationName,
          },
        };
        const partsQuery = [];
        const partArray = order.parts;
        if (partArray.some((p) => p.ApparelSize && p.ApparelSize.label_size)) {
          const partSizes = [].concat.apply(
            [],
            partArray.map((i) => {
              return {
                ...i.ApparelSize,
                partId: i.partId,
                quantity: i.quantity,
              };
            })
          );
          partSizes.forEach((size) => {
            if (size.quantity) partsQuery.push(`${size.partId}_${size.label_size}_${size.quantity}`);
          });
        } else {
          order.parts.forEach((part) => {
            partsQuery.push(`${part.partId}_${part.quantity}`);
          });
        }
        query.query.part = partsQuery;
        return query;
      },
      deleteConfirm(order) {
        this.cartItemToDelete = order;
        this.$refs.deleteConfirm.show();
      },
      deleteCartItem() {
        this.$emit('deleteCartItem', this.cartItemToDelete);
        this.$refs.deleteConfirm.hide();
        this.cartItemToDelete = null;
      },
    },
    computed: {
      ordersLoaded() {
        return this.ordersByCartStatus.every((i) => i.orderId);
      },
      cartStatus() {
        return this.cartType !== 'Cart' ? `${this.cartType} Cart` : this.cartType;
      },
      ordersByCartStatus() {
        return this.orders.filter((i) => i.orderStatus === this.cartStatus);
      },
    },
  };
