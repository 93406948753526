export function mediaHasClassTypeIds(media, ids) {
  return (
    ids.includes(media.classTypeId) ||
    (media.classTypes || []).some(
      (classType) =>
        ids.includes(classType.id) ||
        ids.includes(classType.class_type_id) ||
        ids.find((e) => {
          try {
            const type_id = e.split('-');
            return (
              type_id && type_id[0] <= classType.class_type_id && type_id[1] && type_id[1] >= classType.class_type_id
            );
          } catch (e) {}
        })
    )
  );
}

export function mediaHasClassTypeId(media, id) {
  return mediaHasClassTypeIds(media, [id]);
}

export function mediaHasClassTypeNames(media, names) {
  const lowerCaseNames = names.map((n) => n.toLowerCase());
  const mediaLowerCaseNames = [
    `${media.classTypeName}`.toLowerCase(),
    ...(media.classTypes || []).map((classType) => `${classType.class_type_name}`.toLowerCase()),
  ];
  return lowerCaseNames.some((name) => mediaLowerCaseNames.includes(name));
}

export function mediaHasClassTypeName(media, name) {
  return mediaHasClassTypeNames(media, [name]);
}

export function getMediaName(media) {
  const classTypes = (media.classTypes || []).map((ct) => ct.class_type_name);
  const urlName = (media.url || '').split('/').pop();
  return classTypes.length > 0 ? classTypes.join(', ') : urlName || '-';
}
